import React from 'react';
import AuthorHome from '../../../Component/AuthorComponent/AuthorHome/AuthorHome';

const AuthorHomePage = () => {
    
    return (
        <div>
            <AuthorHome></AuthorHome>
        </div>
    );
};

export default AuthorHomePage;