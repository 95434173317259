import React from 'react';
import Form from '../../../Component/AuthorComponent/PaperSubmission/Form'

const SubmitPaperForm = () => {
    return (
        <div>
            <Form></Form>
        </div>

    );
};

export default SubmitPaperForm;