import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';  // Corrected import
import './support.css';

const Support = () => {
  return (
    <div className="support-container">
      <h4 className='text-center'>Contact Support</h4>
      <h6>We're here to assist you. Reach out to us through any of the following channels:</h6> {/* New h4 Heading */}
      <div className="support-info">
        <div className="support-item">
          <FontAwesomeIcon icon={faEnvelope} className="support-icon" />
          <a href="mailto:support@example.com" className="support-text">Email: support@example.com</a>
        </div>
        <div className="support-item">
          <FontAwesomeIcon icon={faPhone} className="support-icon" />
          <a href="tel:+11234567890" className="support-text">Phone: +1 (123) 456-7890</a>
        </div>
        <div className="support-item">
          <FontAwesomeIcon icon={faWhatsapp} className="support-icon" />
          <a href="https://wa.me/12345678901" target="_blank" rel="noopener noreferrer" className="support-text">WhatsApp: +1 (234) 567-8901</a>
        </div>
      </div>
    </div>
  );
};

export default Support;
