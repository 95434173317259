import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
import './ManuallyAddPaper.css';

const AddPaper = () => {
  const [formData, setFormData] = useState({
    role: '',
    title: '',
    abstract: '',
    keywords: '',
    mainDomain: '',
    subDomain1: '',
    subDomain2: '',
    authors: [{ firstName: '', lastName: '', email: '' }],
    file: null,
  });
  const [domains, setDomains] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchDomains = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_BACKEND_SERVER_URL}/api/v1/admin/get-cutomize-domains`);
        setDomains(response.data);
      } catch (error) {
        console.error('Error fetching domains:', error);
      }
    };
    fetchDomains();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
  
    if (name === "keywords") {
      const keywordsArray = value.split(',').map((keyword) => keyword.trim());
      if (keywordsArray.length > 5) {
        Swal.fire({
          icon: 'warning',
          title: 'Maximum 5 keywords allowed',
          text: 'Please enter no more than 5 keywords.',
        });
        return;
      }
    }
  
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  

  const handleAuthorChange = (index, e) => {
    const { name, value } = e.target;
    const authors = [...formData.authors];
    authors[index][name] = value;
    setFormData((prev) => ({
      ...prev,
      authors,
    }));
  };

  const handleAddAuthor = () => {
    setFormData((prev) => ({
      ...prev,
      authors: [...prev.authors, { firstName: '', lastName: '', email: '' }],
    }));
  };

  const handleRemoveAuthor = (index) => {
    const authors = formData.authors.filter((_, i) => i !== index);
    setFormData((prev) => ({
      ...prev,
      authors,
    }));
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setFormData((prev) => ({
      ...prev,
      file,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const formDataToSend = new FormData();
    formDataToSend.append('role', formData.role);
    formDataToSend.append('title', formData.title);
    formDataToSend.append('abstract', formData.abstract);
    formDataToSend.append('keywords', formData.keywords);
    formDataToSend.append('mainDomain', formData.mainDomain);
    formDataToSend.append('subDomain1', formData.subDomain1);
    formDataToSend.append('subDomain2', formData.subDomain2);
    formDataToSend.append('authors', JSON.stringify(formData.authors));
    formDataToSend.append('file', formData.file);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_SERVER_URL}/api/v1/admin/manually-add-paper`,
        formDataToSend,
        { headers: { 'Content-Type': 'multipart/form-data' } }
      );
      setLoading(false);
      Swal.fire({
        icon: 'success',
        title: 'Paper added successfully!',
      });
      navigate('/admin/customize-paper');
    } catch (error) {
      setLoading(false);
      console.error('Error submitting paper:', error);
      Swal.fire({
        icon: 'error',
        title: 'Failed!',
        text: 'Failed to add paper.',
      });
    }
  };

  const handleMainDomainChange = (e) => {
    const mainDomain = e.target.value;
    setFormData((prev) => ({
      ...prev,
      mainDomain,
      subDomain: '',
    }));
  };

  const handleSubDomainChange1 = (e) => {
    setFormData((prev) => ({
      ...prev,
      subDomain1: e.target.value,
    }));
  };

  const handleSubDomainChange2 = (e) => {
    setFormData((prev) => ({
      ...prev,
      subDomain2: e.target.value,
    }));
  };

  return (
    <div className="add-paper-container">
      <div className="add-paper-form">
        <h2 className="form-title">Add Paper Manually</h2>
        <form onSubmit={handleSubmit}>
          {/* Role */}
          <div className="form-group">
            <label>Role:</label>
            <select name="role" value={formData.role} onChange={handleChange} required>
              <option value="">Select Role</option>
              <option value="Author">Author</option>
              <option value="Reviewer">Reviewer</option>
            </select>
          </div>

          {/* Title */}
          <div className="form-group">
            <label>Title:</label>
            <input
              type="text"
              name="title"
              value={formData.title}
              onChange={handleChange}
              required
            />
          </div>

          {/* Abstract */}
          <div className="form-group">
            <label>Abstract:</label>
            <textarea
              name="abstract"
              value={formData.abstract}
              onChange={handleChange}
              required
            />
          </div>

          {/* Keywords */}
          <div className="form-group">
            <label>Keywords (Maximum 5 keywords, separated by comma):</label>
            <input
              type="text"
              name="keywords"
              value={formData.keywords}
              onChange={handleChange}
            />
          </div>

          {/* Main Domain */}
          <div className="form-group">
            <label>Main Domain:</label>
            <select
              name="mainDomain"
              value={formData.mainDomain}
              onChange={handleMainDomainChange}
              required
            >
              <option value="">Select Main Domain</option>
              {domains.map((domain) => (
                <option key={domain.mainDomain} value={domain.mainDomain}>
                  {domain.mainDomain}
                </option>
              ))}
            </select>
          </div>

          {/* Sub Domain1 */}
          <div className="form-group">
            <label>Sub Domain 1:</label>
            <select
              name="subDomain1"
              value={formData.subDomain1}
              onChange={handleSubDomainChange1}
              required
              disabled={!formData.mainDomain}
            >
              <option value="">Select Sub Domain 1</option>
              {domains
                .find((domain) => domain.mainDomain === formData.mainDomain)
                ?.subDomains.map((subDomain, index) => (
                  <option key={index} value={subDomain}>
                    {subDomain}
                  </option>
                ))}
            </select>
          </div>

          {/* Sub Domain2 */}
          <div className="form-group">
            <label>Sub Domain 2:</label>
            <select
              name="subDomain2"
              value={formData.subDomain2}
              onChange={handleSubDomainChange2}
              required
              disabled={!formData.mainDomain}
            >
              <option value="">Select Sub Domain 2</option>
              {domains
                .find((domain) => domain.mainDomain === formData.mainDomain)
                ?.subDomains.map((subDomain, index) => (
                  <option key={index} value={subDomain}>
                    {subDomain}
                  </option>
                ))}
            </select>
          </div>

          {/* Authors */}
          <div className="authors-section">
            <h3>Authors</h3>
            {formData.authors.map((author, index) => (
              <div key={index} className="author">
                <div className="author-input">
                  <label>First Name:</label>
                  <input
                    type="text"
                    name="firstName"
                    value={author.firstName}
                    onChange={(e) => handleAuthorChange(index, e)}
                    required
                  />
                </div>
                <div className="author-input">
                  <label>Last Name:</label>
                  <input
                    type="text"
                    name="lastName"
                    value={author.lastName}
                    onChange={(e) => handleAuthorChange(index, e)}
                    required
                  />
                </div>
                <div className="author-input">
                  <label>Email:</label>
                  <input
                    type="email"
                    name="email"
                    value={author.email}
                    onChange={(e) => handleAuthorChange(index, e)}
                    required
                  />
                </div>
                {formData.authors.length > 1 && (
                  <button
                    type="button"
                    className="remove-author"
                    onClick={() => handleRemoveAuthor(index)}
                  >
                    Remove Author
                  </button>
                )}
              </div>
            ))}
            <button
              type="button"
              className="add-author"
              onClick={handleAddAuthor}
            >
              Add More Author
            </button>
          </div>

          {/* File Upload */}
          <div className="form-group">
            <label>Upload Paper:</label>
            <input type="file" onChange={handleFileChange} required />
          </div>

          {/* Submit Button */}
          <button type="submit" disabled={loading}>
            {loading ? 'Loading submission...' : 'Submit Paper'}
          </button>
        </form>
      </div>
    </div>
  );
};

export default AddPaper;
