import React, { useEffect, useState } from "react";
import axios from "axios";
import jsPDF from "jspdf";
import "jspdf-autotable";
import "./PrintPaperSummary.css";

const PrintPaperSummary = () => {
  const [papers, setPapers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    const fetchPapers = async () => {
      try {
        setLoading(true);
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_SERVER_URL}/api/v1/submit/get-papers`
        );
        if (response.data?.data) {
          setPapers(response.data.data);
        } else {
          setError("Unexpected data format received from the server.");
        }
      } catch (error) {
        setError("Failed to fetch papers. Please try again later.");
      } finally {
        setLoading(false);
      }
    };

    fetchPapers();
  }, []);

  const handleDownloadPDF = () => {
    const doc = new jsPDF();
    doc.text("Paper Summary", 14, 10);

    const tableData = papers.map((paper) => [
      paper.paperID,
      paper.title,
      paper.author && Array.isArray(paper.author) && paper.author.length > 0
        ? paper.author
            .map((author) => `${author.firstName} ${author.lastName}`)
            .join(", ")
        : "No authors available",
    ]);

    doc.autoTable({
      head: [["Paper ID", "Paper Title", "Authors"]],
      body: tableData,
    });

    doc.save("Paper_Summary.pdf");
  };

  const sortedPapers = papers.sort((a, b) => a.paperID - b.paperID);

  return (
    <div className="print-paper-summary-container">
      <h2 className="page-title">Paper Summary</h2>

      {loading ? (
        <p>Loading...</p>
      ) : error ? (
        <p className="error-message">{error}</p>
      ) : sortedPapers && sortedPapers.length > 0 ? (
        <div>
          <table className="paper-table">
            <thead>
              <tr>
                <th>Paper ID</th>
                <th>Paper Title</th>
                <th>Authors</th>
              </tr>
            </thead>
            <tbody>
              {sortedPapers.map((paper) => {
                const authors =
                  paper.author && Array.isArray(paper.author)
                    ? paper.author
                    : [];
                return (
                  <tr key={paper.paperID}>
                    <td>{paper.paperID}</td>
                    <td>{paper.title}</td>
                    <td>
                      {authors.length > 0
                        ? authors
                            .map(
                              (author) =>
                                `${author.firstName} ${author.lastName}`
                            )
                            .join(", ")
                        : "No authors available"}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          <button className="download-button" onClick={handleDownloadPDF}>
            Download Summary
          </button>
        </div>
      ) : (
        <p>No papers available to display.</p>
      )}
    </div>
  );
};

export default PrintPaperSummary;
